<template>
    <div class=container>
        <div class=table width=100% align="center">                      <font size="6" color="#000099"><b><font face="Verdana, Arial, Helvetica, sans-serif">
                    </font></b></font>
                      <center>
                        <font size="+2">Time Lapse Videos </font>                        
                      </center> 
                      <font color="#000033"><br>
                      </font>
                      <div align="center"><font color="#000033"><br>
                      </font>
                        <table width="92%" height="88" border="0" align="center" cellpadding="5" bgcolor="#FFFFCC">
                          <tbody><tr>
                            <td height="84"><div align="center">
                                <p class="style3"><font face="Verdana, Arial, Helvetica, sans-serif">Following are five time-lapse videos that illustrate the breadth of the data in WILD. They are much lower-resolution than the actual data in WILD and do not have ground truth. They have also been brightened. They are for vizualization purposes only. Click <router-link to="/repository/WILD/request"> here</router-link> to request data from the WILD administrators.</font></p>
                            </div></td>
                          </tr>
                        </tbody></table>
                        <br>
                        <br>
                        <table width="54%" border="2" height="264" cellpadding="5">
                          <tbody><tr valign="middle">
                            <td width="32%" height="256" valign="top"><div align="center" class="style3"><font face="Verdana, Arial, Helvetica, sans-serif"><a href="https://cave.cs.columbia.edu/old/software/wild/videos/may_low.avi"><img src="https://cave.cs.columbia.edu/old/software/wild/videos/may_low_frame.jpg" width="160" height="120" border="0"></a><br>
                            <br>
        Sky: <b>Mostly clear</b><br>
        Weather: <b>Clear</b><br>
        Visibility: <b>10 miles</b><br>
        Size: <b>29 MB</b> </font></div></td>
                            <td width="35%" height="256" valign="top"><div align="center" class="style3"><font face="Verdana, Arial, Helvetica, sans-serif"><a href="https://cave.cs.columbia.edu/old/software/wild/videos/mar_low.avi"><img src="https://cave.cs.columbia.edu/old/software/wild/videos/mar_low_frame.jpg" width="160" height="120" border="0"></a><br>
                            <br>
        Sky: <b>Overcast</b><br>
        Weather: <b>Mist</b><br>
        Visibility: <b>Low</b><br>
        Size: <b>20 MB</b> </font></div></td>
                            <td width="33%" height="256" valign="top"><div align="center" class="style3"><a href="https://cave.cs.columbia.edu/old/software/wild/videos/jan_low.avi"><img src="https://cave.cs.columbia.edu/old/software/wild/videos/jan_low_frame.jpg" width="160" height="120" border="0"></a><br>
                                    <br>
                                    <font face="Verdana, Arial, Helvetica, sans-serif">Sky: <b>Overcast</b><br>
        Weather: <b>Clear, then snow and rain</b><br>
        Visibility: <b>Low</b><br>
        Size: <b>24 MB</b> </font></div></td>
                          </tr>
                        </tbody></table>
                        <br>
                        <table width="38%" border="2">
                          <tbody><tr>
                            <td width="51%" height="256" valign="top"><div align="center" class="style3"><a href="https://cave.cs.columbia.edu/old/software/wild/videos/dec_low.avi"><br>
                                      <img src="https://cave.cs.columbia.edu/old/software/wild/videos/dec_low_frame.jpg" width="160" height="120" border="0"></a><br>
                                      <br>
                                      <font face="Verdana, Arial, Helvetica, sans-serif">Sky: <b>Cloudy</b><br>
        Weather: <b>Rain and mist</b><br>
        Visibility: <b>Low</b><br>
        Size: <b>24 MB</b> </font></div></td>
                            <td width="49%" valign="top"><div align="center" class="style3"><a href="https://cave.cs.columbia.edu/old/software/wild/videos/apr_low.avi"><br>
                                      <img src="https://cave.cs.columbia.edu/old/software/wild/videos/apr_low_frame.jpg" width="160" height="120" border="0"></a><br>
                                      <br>
                                      <font face="Verdana, Arial, Helvetica, sans-serif">Sky: <b>Cloudy</b><br>
        Weather: <b>Heavy rain at times</b><br>
        Visibility: <b>Low</b><br>
        Size: <b>14.3MB</b> </font></div></td>
                          </tr>
                        </tbody></table>
                        <br>
                        <br>
                        <br>
                        <!-- <table width="100%" border="0">
                          <tbody><tr>
                            <td width="49%"><div align="center" class="style3"><font face="Verdana, Arial, Helvetica, sans-serif">To view longer monthly time-lapse videos, click [&nbsp;<a href="https://cave.cs.columbia.edu/old/software/wild/monthly_videos.php">here</a>&nbsp;]</font></div></td>
                          </tr>
                        </tbody></table> -->
                        <table width="100%" border="0">
  <tbody><tr>
    <td width="49%"><div align="center"><font face="Verdana, Arial, Helvetica, sans-serif" size="2">[ <router-link to="/repository/WILD">Back to WILD Home</router-link> ]</font></div></td>
  </tr>
</tbody></table>
                        <font color="#000033">                                                </font>
                      </div>
                      <br>
                      <br>
                      <hr width="700" size="0">
                      <br>
</div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:0 auto;
}
</style>